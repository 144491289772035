<label class="label-text">{{ label | translate }}</label>

@if(type=='user' && user){
  <div class="user-widget d-flex">
    <app-user-image
      [imageUrl]="user.profile_image_path"
      [initial]="user.initial"
      [color]="user.color"
      [type]="'small'">
    </app-user-image>
    <span class="user-name">{{user.first_name}} {{user.last_name}}</span>
  </div>
}@else if (type=='status' && status) {
  <p class="p-text">
    <span class="{{ getClass(status.color_code) }}">{{status.title}}</span>
  </p>
}@else if (type=='list' && list) {
  <ul>
    @for(item of list; track item){
      <li>{{ item.item }}</li>
    }
  </ul>
}@else if (type=='document' && document) {
  <p class="p-text">
    <app-icon [icon]="'pdf-tab'"></app-icon>
    <a [href]="document.downloadUrl" class="p-text a-link d-inline-block cursor-pointer" >
      {{document.original_name}}
    </a>
  </p>
}@else if (type=='documents' && documents) {

  <p class="p-text">

  @for (document of documents; track $index) {
    <div class="inline-icon-link">
      <app-icon [icon]="'pdf-tab'"></app-icon>
      <a [href]="document.downloadUrl" class="p-text a-link d-inline-block cursor-pointer" >
        {{document.original_name}}
      </a>
      <br>
  </div>
  }

</p>

}@else if (value === undefined || value === null || value === '') {
  <p class="p-text">{{  value ?? '--' }}</p>
}@else if(type=='number'){
  <p class="p-text">{{  value }}</p>
}@else if(type=='text'){
  <p class="p-text">{{  value }}</p>
}@else if(type=='datetime'){
  <p class="p-text">{{  value | dateTimeFormat }}</p>
}@else if (type=='para') {
  <p class="p-text">
    <app-read-more [text]="value.toString()" [maxLength]="maxLength"></app-read-more>
  </p>
}
