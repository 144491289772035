import { Component, Input } from '@angular/core';
import { TruncatePipe } from '../../../../../core/pipes/truncate.pipe';

@Component({
  selector: 'app-read-more',
  standalone: true,
  imports: [TruncatePipe],
  templateUrl: './read-more.component.html',
  styleUrl: './read-more.component.scss',
})
export class ReadMoreComponent {
  @Input() maxLength: number = 100;
  @Input() text: string;
  isExpanded: boolean = false;

  toggleReadMore(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isExpanded = !this.isExpanded;
  }
}
